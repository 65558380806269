import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout";
import SEO from "../components/SEO";

class Impressum extends Component {
    render() {
        const currentPage = this.props.data.wordpressPage;
        return (
            <Layout>
                <SEO title="Impressum" />
                <div style={{padding: '2em'}} dangerouslySetInnerHTML={{__html: currentPage.content}} />
            </Layout>
        )
    }
}

export default Impressum

export const pageQuery = graphql`
    query {
        wordpressPage(slug: { eq: "impressum" }) {
            title
            date(formatString: "MMMM DD, YYYY")
            content
        }
    }
`;